<template>
  <div>
    <b-card title="Vous pouvez rechercher les out of stock orders directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="orders && orders.length"
          cols="2"
        >
          <download-csv
            :data="orders"
            name="order.csv"
          >
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="orders"
          :fields="fields"
          :busy="loading"
        >

          <template #cell(number)="data">
            <a :href="`/commande/${data.item.number}`" target="_blank">{{ data.item.number }}</a>
          </template>
          <template #cell(date_created)="data">
            <p>{{ $moment(data.item.date_created).format('DD/MM/YYYY') }}</p>
          </template>
          <template #cell(x)="data">
            <b-badge v-if="data.item.outOfStockInfo" variant="light-success">
              <span class="align-middle">Déjà traité ({{ $moment(data.item.outOfStockInfo).format('DD/MM/YYYY') }})</span>
            </b-badge>
            <feather-icon
              v-else
              icon="CheckIcon"
              size="24"
              class="trash text-warning mr-2 cursor-pointer"
              @click="checkAlert(data.item)"
            />
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BBadge,
  BTable,
  VBPopover,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      dateFilter: null,
      required,
      baseOrders: [],
      orders: [],
      products: [],
      categories: [],
      order: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'number', label: 'order', sortable: true }, { key: 'date_created', label: 'order date', sortable: true }, { key: 'sku', sortable: true }, { key: 'name', label: 'product', sortable: true }, { key: 'email', sortable: true }, 'x'],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getOrders()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getOrders() {
      try {
        this.loading = true
        const {
          orders,
        } = await this.$http.get('/admin/orders/out-of-stock')
        this.orders = orders
        this.baseOrders = orders
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async checkAlert(item) {
      try {
        await this.$http.delete(`/admin/orders/${item.number}/check-stock-alert`)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien enregistré',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getOrders()
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.orders = this.baseOrders
      } else {
        const value = this.code
        console.log(value)
        this.orders = this.baseOrders.filter(order => order.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
